<template>
  <div>
    <!-- 回覆對話串 -->
    <v-card
      max-height="450"
      elevation="8"
      :loading="loading"
      class="overflow-auto px-8 py-8 contact-comments-container"
    >
      <v-btn
        block
        color="primary lighten-2"
        outlined
        @click="loadComments(true)"
        class="mb-4"
        v-if="hasMore"
      >
        {{'action.more'| t}}
      </v-btn>

      <contactCommentItem
        v-for="(comment, index) in comments"
        :key="comment.id"
        :index="index"
        :comment="comment"
        :contact="contact"
      ></contactCommentItem>
    </v-card>

    <!-- 回覆功能 -->
    <div v-if="belongToMember">
      <v-textarea
        maxlength="500"
        class="mt-8"
        counter
        dense
        outlined
        rows="8"
        :label="$t('data.reply_content')"
        :loading="replyLoading"
        v-model="replyContent"
      ></v-textarea>

      <v-btn
        color="primary"
        :loading="replyLoading"
        :disabled="disabledReply"
        @click="reply"
      >
        {{'action.reply'| t}}
      </v-btn>
    </div>

    <div v-if="!belongToMember" class="my-8 title">
      {{ 'contact.guest_message.disabled_reply'|t }}
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
    contactId: {
      type: String,
      default: null,
    },
    contact: Object,
  },
  data: () => ({
    comments: [],
    pager: null,
    loading: false,
    replyContent: null,
    replyLoading: false,
  }),
  methods: {
    scrollToTop() {
      const $contactCommentsContainer = $(this.$el).find('.contact-comments-container')
      $contactCommentsContainer.animate({
        scrollTop: 0
      }, 300)
    },
    scrollToBottom() {
      const $contactCommentsContainer = $(this.$el).find('.contact-comments-container')
      const scrollHeight = $contactCommentsContainer[0].scrollHeight
      $contactCommentsContainer.animate({
        scrollTop: scrollHeight
      }, 300)
    },
    async loadComments(isNextPage = false) {
      if(!this.contactId) {
        this.comments = []
        this.pager = null
        return
      }

      this.loading = true
      let params = {
        page: !isNextPage ? 1 : (this.page+1),
        filter: { contact_id: this.contactId },
        sort: { created_at: 'desc' },
      }

      let result = null
      try {
        result = await this.$api.collection.contactCommentApi.index(params)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }

      await this.handleResult(result, isNextPage)
    },
    async handleResult(result, isNextPage) {

      if(!isNextPage) {
        this.comments = result.data.reverse()
      }

      else {
        this.comments = result.data.reverse().concat(this.comments)
      }
      
      this.pager = result.pager

      await this.$helper.delay(1)
      if(!isNextPage) this.scrollToBottom()
      else this.scrollToTop()
    },
    async reply() {
      this.replyLoading = true
      try {
        const result = await this.$api.collection.contactApi.reply(this.contactId, {
          content: this.replyContent
        })
        this.comments.push(result)
        this.$snotify.success(null, this.$t('reply.successfully'))
        this.replyContent = null
        this.scrollToBottom()
      } catch (error) {
        console.error(error)
        this.$snotify.error(this.$t('error.unexpect'), this.$t('reply.failure'))
      } finally {
        this.replyLoading = false
      }
    },
  },
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    belongToMember() {
      if(!this.formData) return
      return !!this.formData.member_id
    },
    disabledReply() {
      return this.$helper.textEmpty(this.replyContent)
    },
    hasMore() {
      return this.$eagleLodash.get(this.pager, ['has_next'])
    },
    page() {
      return this.$eagleLodash.get(this.pager, ['page'])
    },
  },
  components: {
    contactCommentItem: () => import('./contactCommentItem.vue'),
  },
  watch: {
    contactId: {
      immediate: true,
      handler() {
        this.loadComments()
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>